<template>
	<b-sidebar
		:id="id"
		sidebar-class="medals-sidebar p-4"
		width="520px"
		backdrop
		right
		no-header
		no-footer
	>
		<div class="medals-sidebar_header d-flex justify-content-between mb-4">
			<TextPlay
				color="var(--fontcolor)"
				size="lg"
				weight="semibold"
				:text="$t('gamification.select_a_medal')"
			/>

			<IconSax
				v-b-toggle="id"
				name="close-circle"
			/>
		</div>

		<div class="medals-sidebar_body">
			<div class="medals-sidebar_configured-medals d-flex flex-column">
				<Medal
					clickable
					v-for="(medal, index) in medalsList"
					:key="index"
					:name="medal.name"
					:picture="medal.emblem.picture"
					horizontal
					class="mb-2"
					@click="$emit('select', medal)"
				/>
			</div>
		</div>
	</b-sidebar>
</template>

<script>
import Medal from "@/components/Medal.vue";
import { mapActions, mapGetters } from "vuex";

export default {
	components: {
		Medal,
	},
	props: {
		id: {
			type: String,
			required: true,
		},
	},
	computed: {
		...mapGetters({
			medalsList: "gamification/getMedals",
		}),
	},
	methods: {
		...mapActions("gamification", ["fetchMedals"]),
	},
	async mounted() {
		await this.fetchMedals();
	},
};
</script>

<style lang="scss">
.medals-sidebar {
	background-color: var(--backgroundcolor) !important;
}
</style>
